<template>
  <router-link to="/manage">
    <v-img
      v-if="webenv === projects.CWM"
      src="@assets/logo/vadofone_logotype_slim.svg"
      contain
      height="48"
      width="48"
      position="left center"
    />

    <v-img
      v-else-if="webenv === projects.KNOX"
      src="@assets/logo/windtre_logotype.svg"
      contain
      height="48"
      width="80"
      position="left center"
    />

    <v-img
      v-else-if="[projects.TUTTOSMART, projects.TIM].includes(webenv)"
      src="@assets/logo/tim_logotype.svg"
      contain
      height="48"
      width="140"
      position="left center"
    />

    <v-img
      v-else-if="webenv === projects.ENI"
      src="@assets/logo/tim_logotype.svg"
      contain
      height="48"
      width="140"
      position="left center"
    />

    <v-img
      v-else
      src="@assets/ennova_logotype.svg"
      contain
      height="48"
      width="140"
      position="left center"
    />
  </router-link>
</template>

<script>
import utils from '@mixins/utils.js'

export default {
  name: 'AppBarLogo',
  mixins: [utils],
}
</script>
